import { Container, Flex, Heading, VStack } from "@chakra-ui/react";

const LandingContainer = ({ title, children, maxW, ...props }) => {
  return (
    <Container
      maxW={maxW || "7xl"}
      px={{ base: 2, md: 16 }}
      // mb={12}
      // pt={8}
      scrollMarginTop="100px"
      {...props}
    >
      <VStack w='full' align="stretch" spacing={4}>
        <Flex justify={{ base: 'start', md: 'center' }}>
          <Heading as='h2' mb={{ base: 0, md: 4 }} >{title}</Heading>
        </Flex>
        {children}
      </VStack>
    </Container>
  );
};

export default LandingContainer;
