"use client";

import {
  Box,
  HStack,
  Heading,
  Text,
  Link,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  SimpleGrid,
  Tr,
  Td,
  Icon,
  Flex,
  Badge,
} from "@chakra-ui/react";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import Cta from "./Cta";
import { MdPhone } from "react-icons/md";
import LandingContainer from "./LandingContainer";

interface Props {
  children: React.ReactNode;
}

const Block = ({ name, starter, pro, enterprise, soon }) => {
  return (
    <Tr>
      <Td fontWeight="bold" as={Flex} justifyContent="space-between">
        {<Text>{name}</Text>}
        {soon && (
          <Text fontSize="xs" color="gray.500">
            Soon
          </Text>
        )}
      </Td>
      <Td textAlign="center">
        {starter ? (
          <Icon as={FaCheck} color="green.500" />
        ) : (
          <Icon as={FaTimes} color="gray.300" />
        )}
      </Td>
      <Td textAlign="center">
        {pro ? (
          <Icon as={FaCheck} color="green.500" />
        ) : (
          <Icon as={FaTimes} color="gray.300" />
        )}
      </Td>
      {/* <Td textAlign="center">
        {enterprise ? (
          <Icon as={FaCheck} color="green.500" />
        ) : (
          <Icon as={FaTimes} color="gray.300" />
        )}
      </Td> */}
    </Tr>
  );
};

function PriceWrapper(props: Props) {
  const { children, ...rest } = props;

  return (
    <Flex
      {...rest}
      mb={4}
      boxShadow={"lg"}
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.300", "gray.500")}
      borderRadius={"xl"}
      h="full"
      direction="column"
    >
      {children}
    </Flex>
  );
}

const Pricing = () => {
  // const savingStarter = Math.round(
  //   100 *
  //     (1 -
  //       import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR_PRICE /
  //         import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR_PRICE)
  // );
  // const savingPro = Math.round(
  //   100 *
  //     (1 -
  //       import.meta.env.VITE_STRIPE_PRO_YEARLY_EUR_PRICE /
  //         import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR_PRICE)
  // );

  const starterPriceWidget = (
    <>
      <HStack justifyContent="center">
        <Text fontSize="3xl" fontWeight="600">
          $
        </Text>
        <Text fontSize="5xl" fontWeight="900">
          {import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR_PRICE}
        </Text>
        <Text color="gray.500">/month</Text>
      </HStack>
    </>
  );
  return (
    <LandingContainer pt={4} mb={0} title="Limited time offer ⏳"
    >
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        textAlign="center"
        spacing={{ base: 4, lg: 4 }}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Heading size="lg">Starter</Heading>
            {starterPriceWidget}
          </Box>
          <Flex
            h="full"
            direction="column"
            justify="space-between"
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Build and deploy 5 flows
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Access to all{" "}
                <Button
                  variant="link"
                  colorScheme="brand"
                  as={Link}
                  href="#blocks"
                >
                  starter
                </Button>{" "}
                blocks
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Standard support
              </ListItem>
            </List>
            <Box pt={4}>
              <Cta size="md" />
            </Box>
          </Flex>
        </PriceWrapper>
        <PriceWrapper>
          <Box position="relative">
            <Box
              display="none"
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform="uppercase"
                bg={useColorModeValue("brand.200", "brand.700")}
                px={3}
                py={1}
                color={useColorModeValue("gray.900", "gray.300")}
                fontSize="lg"
                fontWeight="600"
                rounded="xl"
              >
                Most Popular
              </Text>
            </Box>
          </Box>
          <Box py={4} px={12}>
            <Heading size="lg"> Pro</Heading>
            <SimpleGrid columns={3} spacing={0}>
              <VStack justifyContent="center"
              >
                <Flex
                  justifyContent="top"
                  alignItems="center"
                  position="relative"
                  color="gray.400"
                >
                  <Text fontSize="2xl">$</Text>
                  <Text fontSize="4xl" fontWeight="600">
                    29
                  </Text>
                  <Box
                    position="absolute"
                    // display="none"
                    width="110%"
                    height="2px" // Adjust thickness
                    backgroundColor="gray.400" // Change color as needed
                    top="50%"
                    zIndex={-1}
                    transform="translateY(-50%)"
                  />
                </Flex>
              </VStack>
              <VStack alignContent="center" >
                {starterPriceWidget}
              </VStack>
              <VStack justifyContent="center">
                <Badge
                  bg={useColorModeValue("brandTernary.200", "brandTernary.200")}
                  fontSize="xl"
                  rounded="full"
                  px="2"
                >
                  Save 69%
                </Badge>
              </VStack>
            </SimpleGrid>
          </Box>
          <Flex
            h="full"
            direction="column"
            justify="space-between"
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Everything in Starter
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Up to 10 flows
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Access to{" "}
                <Button
                  variant="link"
                  colorScheme="brand"
                  as={Link}
                  href="#blocks"
                >
                  all blocks
                </Button>
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Pro support
              </ListItem>
            </List>
            <Box pt={4}>
              <Cta size="md" />
            </Box>
          </Flex>
        </PriceWrapper>
        <PriceWrapper display="none">
          <Box py={4} px={12}>
            <Text fontWeight="bold" fontSize="2xl">
              Enterprise
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" color="gray.500">
                Custom
              </Text>
            </HStack>
          </Box>
          <Flex
            h="full"
            direction="column"
            justify="space-between"
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Everything in Pro
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Custom private blocks for your APIs or logic
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                On-premise deployment
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Dedicated support
              </ListItem>
            </List>
            <Box pt={4}>
              <VStack spacing={1}>
                <Button
                  size="md"
                  variant="outline"
                  colorScheme="brand"
                  as={Link}
                  href="#contact"
                  leftIcon={<MdPhone />}
                >
                  Talk to us
                </Button>
                <Text fontSize="lg" mt="2" visibility="hidden">
                  No strings attached
                </Text>
              </VStack>
            </Box>
          </Flex>
        </PriceWrapper>
      </SimpleGrid>
    </LandingContainer>
  );
};

export default Pricing;
