"use client";

import {
  Flex,
  Box,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { SiDiscord } from "@react-icons/all-files/si/SiDiscord";

const Contact = () => {
  const brandColor = useColorModeValue("brandTernary.200", "brand.100");
  return (
    <Flex p={8} bg={brandColor} rounded='10' justify='center'>
      <Box p={4} shadow='2xl' bg='white' rounded='10'>
        <Text
          mt={{ sm: 3, md: 3, lg: 5 }}
        >
          Have another question? Contact us!{' '}
          <Text as='span' fontWeight='bold'>We answer quickly.</Text>
        </Text>
        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
          <VStack pl={0} spacing={3} align="stretch" fontWeight={600}>
            <HStack>
              <MdEmail />
              <Link isExternal href="mailto:hello@tryplurally.com">
                hello@tryplurally.com
              </Link>
            </HStack>
            <HStack>
              <SiDiscord />
              <Link href="https://discord.gg/aGpMJfYpDb" isExternal>
                Discord
              </Link>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default Contact;
