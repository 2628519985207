import { Button, Text, VStack } from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";
import { navigate } from 'vike/client/router'
import { FaRocket } from "@react-icons/all-files/fa/FaRocket";

interface CtaProps {
  size: string;
}
const pxs = {
  lg: 16,
  xl: 16,
};

const Cta = ({ size = "md" }: CtaProps) => {
  const buttonAttrs = {
    props: {
      size,
      px: pxs[size],
      colorScheme: "brand",
    },
    content: "Build your first flow",
    subtitle: "14 days trial, no credit card required!"
  }
  const { props: { ...props } } = buttonAttrs;
  const nav = () => {
    amplitude.track("landing_cta_click", buttonAttrs);
    navigate("/auth");
  }
  return (
    <VStack spacing={1}>
      <Button
        onClick={nav}
        {...props}
        leftIcon={<FaRocket />}
      >
        {buttonAttrs.content}
      </Button>
      <Text fontWeight="bold" fontSize="lg" mt="2">
        {buttonAttrs.subtitle}
      </Text>
    </VStack>
  );
};

export default Cta;
