"use client";

import {
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Icon,
    Flex,
    Box,
    Image,
} from "@chakra-ui/react";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import LandingContainer from "./LandingContainer";
import RequestIntegration from "./request-integration";

interface Props {
    children: React.ReactNode;
}

const Block = ({ name, starter, pro, proOnRequest, soon, icon }) => {
    return (
        <Tr>
            <Td fontWeight="bold" justifyContent="space-between">
                <HStack align='center'>
                    <Box boxSize={8}>
                        {icon && <Image src={`/logos/${icon}.svg`} />}
                    </Box>
                    <Box>
                        {<Text>{name}</Text>}
                        {soon && (
                            <Text fontSize="xs" color="gray.500">
                                Soon
                            </Text>
                        )}
                    </Box>
                </HStack>
            </Td>
            <Td textAlign="center">
                {starter ? (
                    <Icon as={FaCheck} color="green.500" />
                ) : (
                    <Icon as={FaTimes} color="gray.300" />
                )}
            </Td>
            <Td textAlign="center">
                {proOnRequest || pro ? (
                    <Icon as={FaCheck} color="green.500" />
                ) : (
                    <Icon as={FaTimes} color="gray.300" />
                )}

                {proOnRequest && <Text fontWeight='bold' fontSize="xs" color="green.500">
                    On request
                </Text>}

            </Td>
            {/* <Td textAlign="center">
        {enterprise ? (
          <Icon as={FaCheck} color="green.500" />
        ) : (
          <Icon as={FaTimes} color="gray.300" />
        )}
      </Td> */}
        </Tr>
    );
};

function PriceWrapper(props: Props) {
    const { children, ...rest } = props;

    return (
        <Flex
            {...rest}
            mb={4}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={useColorModeValue("gray.300", "gray.500")}
            borderRadius={"xl"}
            h="full"
            direction="column"
        >
            {children}
        </Flex>
    );
}

const IntegrationsWidget = () => {
    // const savingStarter = Math.round(
    //   100 *
    //     (1 -
    //       import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR_PRICE /
    //         import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR_PRICE)
    // );
    // const savingPro = Math.round(
    //   100 *
    //     (1 -
    //       import.meta.env.VITE_STRIPE_PRO_YEARLY_EUR_PRICE /
    //         import.meta.env.VITE_STRIPE_MONTHLY_YEARLY_EUR_PRICE)
    // );


    const starterPriceWidget = (
        <>
            <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                    $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                    {import.meta.env.VITE_STRIPE_INDIVIDUAL_YEARLY_EUR_PRICE}
                </Text>
                <Text color="gray.500">/month</Text>
            </HStack>
        </>
    );
    return (
        <>
            <LandingContainer title='All the integrations you need'>
                <VStack
                    spacing={2}
                    w='full'
                    align="stretch"
                    id="blocks"
                    scrollMarginTop="100px"
                >
                    <TableContainer pt={10}>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th fontSize="md">Blocks</Th>
                                    <Th fontSize="md" textAlign="center">
                                        Starter
                                    </Th>
                                    <Th fontSize="md" textAlign="center">
                                        Pro
                                    </Th>
                                    {/* <Th fontSize="md" textAlign="center">
                  Enterprise
                </Th> */}
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Block name="AI" icon='openai' starter={true} pro={true} enterprise={true} />
                                <Block name="Email" icon='gmail' starter={true} pro={true} enterprise={true} />
                                <Block
                                    name="Google Sheets"
                                    icon='gsheets'
                                    starter={true}
                                    pro={true}
                                    enterprise={true}
                                />
                                <Block
                                    name="Hubspot"
                                    icon='hubspot'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                />
                                <Block
                                    name="Leexi"
                                    icon='leexi'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                />
                                <Block
                                    name="Instagram"
                                    icon='instagram'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                />
                                <Block
                                    name="Google Calendar"
                                    icon='gcalendar'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                    soon
                                />
                                <Block
                                    name="Slack"
                                    icon='slack'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                    soon
                                />
                                <Block
                                    name="SalesForce"
                                    icon='salesforce'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                    soon
                                />
                                <Block
                                    name="Shopify"
                                    icon='shopify'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                    soon
                                />
                                <Block
                                    name="Etsy"
                                    icon='etsy'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                    soon
                                />
                                <Block
                                    name="Web Scraping"
                                    icon='scrape-one'
                                    starter={false}
                                    pro={true}
                                    enterprise={true}
                                />
                                <Block
                                    name="Custom Blocks"
                                    starter={false}
                                    pro={true}
                                    proOnRequest
                                    enterprise={true}
                                    soon
                                />
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
            </LandingContainer>
            <RequestIntegration />
        </>
    );
};

export default IntegrationsWidget;
