import {
  Box,
  Center,
  Spinner,
  VStack,
} from "@chakra-ui/react";

import SCHEMAS from "src/res/demo-node-types.json";
import { useState } from "react";
import { ReactFlow, useNodesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { createCustomNodes } from "src/appUtils";
import annotationNode from "./annotation-node";

const initialNodes = [[
  {
    id: 'annotation-1',
    type: 'annotation',
    draggable: false,
    selectable: false,
    data: {
      level: 1,
      label:
        'Plurally fetches your meeting information from Leexi as soon as they are ready.',
      arrowStyle: {
        right: 0,
        bottom: 0,
        transform: 'translate(-30px,10px) rotate(-80deg)',
      },
    },
    position: { x: -200, y: -30 },
  },
  {
    id: 'annotation-2',
    type: 'annotation',
    draggable: false,
    selectable: false,
    data: {
      level: 2,
      label:
        'It instructs an AI to generate HubSpot objects to reflect what happened in the meeting.',
      arrowStyle: {
        left: 0,
        bottom: 0,
        transform: 'translate(5px, 25px) scale(1, -1) rotate(100deg)',
      },
    },
    position: { x: 200, y: 108 },
  },
  {
    id: 'annotation-3',
    type: 'annotation',
    draggable: false,
    selectable: false,
    data: {
      level: 3,
      label:
        'Your HubSpot objects are automatically updated with the latest information.',
      arrowStyle: {
        right: 0,
        bottom: 0,
        transform: 'translate(-30px,10px) rotate(-80deg)',
      },
    },
    position: { x: -180, y: 250 },
  },
  {
    id: "leexi",
    type: "Leexi",
    data: {
      src_handles: ["summary", "participants"],
      name: "New meeting summary",
      overrides: {
        kls: "Leexi",
      },
    },
    position: { x: 0, y: 0 },
  },
  {
    id: "gpt",
    type: "Instruct2",
    data: {
      tgt_handles: ["summary", "participants"],
      src_handles: ["hubspot_commands"],
      name: "Generate HubSpot objects",
      overrides: {
        kls: "AI",
      },
    },
    position: { x: -9, y: 158 },
  },
  {
    id: "hubspot",
    type: "HubspotAuto",
    data: {
      tgt_handles: ["hubspot_commands"],
      overrides: {
        kls: "HubSpot",
      },
      name: "Sync HubSpot",
    },
    input_schema: {
      properties: {
        hubspot_commands: {
          type: "array",
          title: "HubSpot commands",
          items: {
            type: "string",
            title: "Command",
          },
        },
      },
    },
    position: { x: 15, y: 306 },
  },
], [
  {
    id: "inbox",
    type: "NewEmail",
    data: {
      src_handles: ["content", "sender_email"],
      name: "Read user's message",
      overrides: {
        kls: "Inbox",
      },
    },
    position: { x: 0, y: 0 },
  },
  {
    id: "outbox",
    type: "SendEmailSMTP",
    data: {
      tgt_handles: ["email_address", "content"],
      name: "Send answer to user",
      overrides: {
        kls: "Outbox",
      },
    },
    position: { x: 0, y: 320 },
  },
  {
    id: "gpt",
    type: "Instruct",
    data: {
      tgt_handles: ["email_content", "knowledge"],
      src_handles: ["answer"],
      name: "Redact answer",
      overrides: {
        kls: "AI",
      },
      output_schema: {
        properties: {
          answer: {
            type: "string",
            title: "Answer",
          },
        },
      },
    },
    position: { x: 100, y: 200 },
  },
  {
    id: "db",
    type: "Database",
    data: {
      overrides: {
        kls: "Knowledge",
      },
      src_handles: ["knowledge"],
      name: "Use previous answers & info",
      output_schema: {
        properties: {
          knowledge: {
            type: "string",
            title: "Knowledge",
          },
        },
      },
    },
    position: { x: 250, y: 0 },
  },
]];

const initialEdges = [[
  {
    id: "leexi-gpt-summary",
    source: "leexi",
    target: "gpt",
    sourceHandle: "summary",
    targetHandle: "summary",
    animated: true,
  },
  {
    id: "leexi-gpt-participants",
    source: "leexi",
    target: "gpt",
    sourceHandle: "participants",
    targetHandle: "participants",
    animated: true,
  },
  {
    id: "gpt-hubspot-commands",
    source: "gpt",
    target: "hubspot",
    sourceHandle: "hubspot_commands",
    targetHandle: "hubspot_commands",
    animated: true,
  },
], [
  {
    id: "inbox-gpt-content",
    source: "inbox",
    target: "gpt",
    sourceHandle: "content",
    targetHandle: "email_content",
    animated: true,
  },
  {
    id: "inbox-outbox-email",
    source: "inbox",
    target: "outbox",
    sourceHandle: "sender_email",
    targetHandle: "email_address",
    animated: true,
  },
  {
    id: "db-gpt-context",
    source: "db",
    target: "gpt",
    sourceHandle: "knowledge",
    targetHandle: "knowledge",
    animated: true,
  },
  {
    id: "inbox-gpt-body-context",
    source: "inbox",
    target: "gpt",
    sourceHandle: "body",
    targetHandle: "email_content",
    animated: true,
  },
  {
    id: "gpt-outbox-answer",
    source: "gpt",
    target: "outbox",
    // label: "Body",
    sourceHandle: "answer",
    targetHandle: "content",
    animated: true,
  },
]];


const Demo = () => {
  const [customNodes] = useState(createCustomNodes({
    nodeTypes: SCHEMAS,
    isDemo: true,
  }));
  const [nodes, _, onNodesChange] = useNodesState(initialNodes[0])
  const [edges] = useState(initialEdges[0]);
  return (
    <VStack h="100%" align="stretch">
      <Box h="100%">
        {customNodes ? (
          <ReactFlow
            // nodeExtent={nodeExtent}
            deleteKeyCode={null}
            onNodesChange={(changes) => {
              changes.forEach((change) => {
                if (change.position) {
                  console.debug(change.position.x, change.position.y);
                }
              });
              onNodesChange(changes)
            }}
            onEdgesChange={() => null}
            onConnect={() => null}
            edgesFocusable={false}
            // nodesDraggable={false}
            nodesConnectable={false}
            nodesFocusable={true}
            elementsSelectable={false}
            // zoomOnScroll={false}
            panOnScroll={false}
            preventScrolling={false}
            nodeTypes={{ ...(customNodes || {}), annotation: annotationNode }}
            nodes={nodes}
            edges={edges}
            // panOnDrag={false}
            fitView
          // maxZoom={1}
          // minZoom={1}
          >
            {/* <Background /> */}
            {/* <Controls /> */}
          </ReactFlow>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Box>
    </VStack>
  );
};

export default Demo;
