import { Box, Button, Text, Flex, VStack } from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";

const RequestIntegration = () => {
  const data = {
    content: "Don’t see the integration you need? You can request it!",
    cta: "Request",
    bg: "brand.50",
    size: "md",
    // subtitle: "You won't need to sign up!",
    subtitle: undefined,
    colorScheme: "brand",
  }
  return (
    <Box
      bg={data.bg}
      color="black"
      py={4}
      px={8}
      textAlign="center"
      width="100%"
    >
      <Flex direction={{ base: "column", md: "row" }} justify="center" align="center">
        <VStack spacing={0} mb={{ base: 4, md: 0 }} me='4' >
          <Text fontSize="lg" fontWeight="bold" >
            {data.content}
          </Text>
          <Text fontSize='sm'>
            {data.subtitle}
          </Text>
        </VStack>
        <Button
          colorScheme={data.colorScheme}
          size={data.size}
          fontWeight="bold"
          onClick={() => {
            amplitude.track("request_integration_click", data);
            window.open("https://forms.gle/XdjdAAs4XejnyTYY6", '_blank').focus();
          }}
        >
          {data.cta}
        </Button>
      </Flex>
    </Box>
  );
};

export default RequestIntegration;
