import {
  Stack,
  Box,
  Heading,
  Text,
  useColorModeValue,
  Image as ChakraImage,
  Flex,
  VStack,
  AvatarGroup,
  Avatar,
  HStack,
  SimpleGrid,
  GridItem,
  Icon,
  Center,
  Button,
  Link,
  Tag,
} from "@chakra-ui/react";

import Pricing from "src/home/pricing";
import Cta from "../../home/Cta";
import Testimonials from "../../home/testimonials";
import LandingContainer from "../../home/LandingContainer";
import RequestIntegration from "../../home/request-integration";
import IntegrationsWidget from "../../home/integrations";
import { ReactFlowProvider } from "@xyflow/react";
import Demo from "src/components/demo";
import React from "react";

import { MdStar } from "@react-icons/all-files/md/MdStar";
import { FaCaretRight } from "@react-icons/all-files/fa/FaCaretRight";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { FaHammer } from "@react-icons/all-files/fa/FaHammer";
import { FaUmbrellaBeach } from "@react-icons/all-files/fa/FaUmbrellaBeach";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";

import { useData } from "vike-react/useData";
import { ExploreCard, TEMPLATES_BGS } from "src/components/explore";
import { createCustomNodes } from "src/appUtils";
import Faq from "src/components/faq";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { FaRocket } from "@react-icons/all-files/fa/FaRocket";

const HeroTitle = ({ children }) => (
  <Heading
    as='h2'
    lineHeight={1.1}
    fontWeight="bold"
    letterSpacing={"-3px"}
    fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
  >
    {children}
  </Heading>
);

const LazyVideo = () => {
  return <LiteYouTubeEmbed
    noCookie
    allowFullScreen
    className='video'
    style={{
      width: '100%',
      height: '100%',
      border: 'none',
    }}
    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
    id="Ilka2CUDGQ0"
    playlabel="Plurally Demo Video" />
}

const Hero = () => {
  const brandColor = useColorModeValue("brandTernary.200", "brandTernary.100");
  return (
    <LandingContainer id="demo" >
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        // py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack
          flex={2}
          spacing={{ base: 4, md: 8 }}
          align={{ base: "center", md: "start" }}
        >
          <HeroTitle>
            <Text
              as={"span"}
              position={"relative"}
              className="hero"
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "primary",
                zIndex: -1,
              }}
            >
              Build any AI flow visually,
            </Text>{" "}
            <Box
              display="inline-block"
              transform="rotate(-2deg)" // Rotate the container
              bg={brandColor}
              p={2}
              borderRadius="md"
            >
              <Text
                as='span'
                display="inline-block"
                transform="rotate(2deg)" // Rotate the text back to normal
              >
                no-code
              </Text>
            </Box>
          </HeroTitle>
          <VStack align="start" position='relative'>
            <Text as='h1'>
              Your ultimate AI flow chart builder with dozens of integrations: build automation workflows for HubSpot, Instagram, Gmail and more.
            </Text>
            <ChakraImage position='absolute' left='-9900px' src={`${import.meta.env.VITE_PUBLIC_HOST}/plurally-cover.jpeg`} alt="Plurally Hero Image" />
          </VStack>
          <VStack spacing={{ base: 4, sm: 6 }} align="center">
            <Cta size="lg" />
            <HStack>
              <AvatarGroup size="sm" max={5}>
                {[
                  "bart", "alexa", "const", "nico"
                ].map((name) => (
                  <Avatar
                    key={name}
                    name={name}
                    src={`testimonials/${name}.webp`}
                  />
                ))}
              </AvatarGroup>
              <VStack align="start" spacing="0">
                <HStack spacing="-2" color="#FFBF00">
                  <MdStar />
                  <MdStar />
                  <MdStar />
                  <MdStar />
                  <MdStar />
                </HStack>
                <Text>
                  <Text fontWeight="bold" as="span">
                    1000+
                  </Text>{" "}
                  flows created!
                </Text>
              </VStack>
            </HStack>
            {/* <Button
              size={"lg"}
              px={6}
              variant="outline"
              colorScheme="brand"
              as={Link}
              leftIcon={<ExternalLinkIcon />}
              isExternal
              href={requestDemoHref}
            >
              Request a demo
            </Button> */}
          </VStack>
        </Stack>
        <Flex
          flex={2}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Box
            position={'relative'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}>
            <LazyVideo />
          </Box>
        </Flex>
      </Stack>

      {/* <Text>
              Flows are built by connecting blocks together to form a pipeline.
              This intuitive way of building AI flows is accessible to anyone,
              regardless of their technical background and gives very fine
              control over the logic.
            </Text> */}
    </LandingContainer>
  );
};

const IntegrationLogos = () => {
  return <VStack align="center" justify='center' h='full' >
    <Text fontWeight="bold" textColor="gray.400">
      Integrate with
    </Text>
    <Flex justify="center" align="center" wrap="wrap" gap={6}>
      <Box>
        <ChakraImage src="/logos/gmail.svg" alt="GMail" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/instagram.svg" alt="Instagram" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/gsheets.svg" alt="GSheets" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/hubspot.svg" alt="Hubspot" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/openai.svg" alt="OpenAI" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/salesforce.svg" alt="Salesforce" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/leexi.svg" alt="Leexi" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/calendar.svg" alt="GCalendar" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/slack.svg" alt="Slack" boxSize="50px" />
      </Box>
    </Flex>
    <Text fontWeight="bold" textColor="gray.400" mb='4'>
      And many more!
    </Text>
  </VStack>
}

const Step = ({
  icon,
  title,
  text,
  selected,
  onClick
}) => {
  const color = selected ? 'brand.500' : 'inherit'
  return <Flex
    borderStartColor={color}
    borderStartWidth={selected ? '2px' : '0px'}
    onClick={onClick}
    justify='space-between'
    align={'center'}
    gap='4'
    _hover={{ bg: 'brand.50' }}
    p={4}
    cursor='pointer'>
    <Flex w={12} h={12} align={'center'} justify={'center'} rounded={'full'} >
      <Icon as={icon} w='6' h='6' color={color} />
    </Flex>
    <Box flex='1'>
      <Heading size='md' as='h3' color={color}>
        {title}
      </Heading>
      <Text color={selected ? 'inherit' : 'gray.600'}>{text}</Text>
    </Box>
    <Icon as={FaCaretRight} color={color} />
  </Flex>
}

const Step1 = () => {
  const INTEGRATIONS = [
    ['hubspot', 'Hubspot'],
    ['gmail', 'Gmail'],
    ['instagram', 'Instagram'],
    ['gsheets', 'Google Sheets'],
    ['shopify', 'Shopify'],
  ]
  const [integration, setIntegration] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIntegration(prevIntegration => (prevIntegration + 1) % INTEGRATIONS.length);
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (<Flex borderColor={useColorModeValue("gray.300", "gray.500")} direction='column' align='center' gap={4} boxShadow='4px 8px 8px #DCDEF6' borderWidth='1px' borderRadius='10' position='relative' p='4' >
    <Tag colorScheme="brand" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
      <Icon as={FaLink} />
      <Text>
        Integrate your services
      </Text>
    </Tag>
    <VStack p={{ base: 4, md: 8 }} align='stretch'>
      <HStack align='center'>
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', md: 'xl' }} src={`/logos/${INTEGRATIONS[integration][0]}.svg`} aria-label={`${INTEGRATIONS[integration][0]} logo`} name={`${INTEGRATIONS[integration][0]} logo`} />
        <Icon as={FaArrowRight} color='gray.400' fontSize='2xl' />
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', md: 'xl' }} src={`/logo-square-white.jpg`} aria-label='Plurally logo' name='Plurally logo' />
      </HStack>
      <Heading>Plurally</Heading>
      <Text>wants to connect to your{' '}<Text fontSize='2xl' fontWeight='bold' as='span'>{INTEGRATIONS[integration][1]}</Text> account.</Text>
      <Text>
        We never store your data. We only use it to run your flows!
      </Text>
      <HStack w='full' justify='end' mt='2'>
        <Button >Continue</Button>
      </HStack>
    </VStack>
  </Flex >)
}

const GettingStarted = () => {

  React.useEffect(() => {
    // Preload images
    const images = ['/landing/step3.webp', '/logo-square-white.jpg'];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const [currentStep, setCurrentStep] = React.useState(0)
  const borderColor = useColorModeValue("gray.300", "gray.500")
  return (
    <LandingContainer px={{ base: 2, sm: 4, lg: 16 }} title='Getting started with Plurally'>
      <SimpleGrid columns={12} rowGap={12} columnGap={{ base: 0, md: 12 }}>
        <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} as={VStack}>
          <VStack align='stretch' py={{ base: 0, lg: 12 }} h='full' justify='center'>
            <Step onClick={() => setCurrentStep(0)} icon={FaHammer} title='Step 1' text='Create a flow by using a template or from scratch' selected={currentStep === 0} />
            <Step onClick={() => setCurrentStep(1)} icon={FaLink} title='Step 2' text='Connect with the necessary integrations' selected={currentStep === 1} />
            <Step onClick={() => setCurrentStep(2)} icon={FaUmbrellaBeach} title='Step 3' text='Relax and watch your flow handle the rest!' selected={currentStep === 2} />
          </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6, lg: 8 }}>
          <HStack w='full' h='500px' align={{ base: 'start', md: 'center' }} justify='center'>
            {currentStep === 0 && <Box borderColor={borderColor} height={"500px"} width={"full"} boxShadow='4px 8px 8px #DCDEF6' borderWidth='1px' borderRadius='10' position='relative' p='4'>
              <Tag colorScheme="brandQuaternary" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                <Icon as={FaHammer} />
                <Text>
                  Build your flow
                </Text>
              </Tag>
              <ReactFlowProvider>
                <Demo />
              </ReactFlowProvider>
            </Box>}
            {
              currentStep === 1 && <Step1 />
            }
            {
              currentStep === 2 &&
              <Flex borderColor={borderColor} direction='column' align='center' gap={4} boxShadow='4px 8px 8px #DCDEF6' borderWidth='1px' borderRadius='10' position='relative' p='4' >
                <Tag colorScheme="green" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                  <Icon as={FaRocket} />
                  <Text>
                    Your flow is running!
                  </Text>
                </Tag>
                <Center>
                  <ChakraImage loading="eager" src='/landing/step3-hubspot.webp' h='300px' objectFit='contain' alt='image of flow running' />
                </Center>
                <VStack align='end' position='absolute' bottom='0' right='0' mx='4'>
                  <HStack position='relative'>
                    <Text fontStyle='italic' fontWeight='bold'>
                      Created by your Plurally flow
                    </Text>
                    <Icon bg='white' rounded='full' as={FaCheckCircle} color='green.500' fontSize='2xl' />
                  </HStack>
                  <Text fontSize='xs' color='gray' fontStyle='italic'></Text>
                </VStack>
              </Flex>
            }
          </HStack >
        </GridItem>
      </SimpleGrid>
    </LandingContainer >
  );
};

const Templates = () => {
  const { templates, nodeTypes } = useData()
  const customNodes = React.useMemo(() => createCustomNodes({ nodeTypes, isDemo: true, defaultIsExpanded: false }), [nodeTypes])
  return <LandingContainer title="Our most popular templates 🔥">
    <VStack align='stretch'>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8 }}
      >
        {
          templates?.slice(0, 3).map(({ template, edges, nodes }, ix) => <ExploreCard
            root="/gallery"
            key={template.id}
            template={template}
            edges={edges}
            nodes={nodes}
            customNodes={customNodes}
            bg={TEMPLATES_BGS[ix % TEMPLATES_BGS.length]}
          />)
        }
      </SimpleGrid>
      <HStack justify={{ base: 'center', md: 'end' }} spacing={4}>
        <Button mt='2' variant='link' leftIcon={<MdChevronRight />}>
          <Link href='/gallery'>
            See more templates
          </Link>
        </Button>
      </HStack>
    </VStack>
  </LandingContainer>
}


const LandingPage = () => {
  return (
    <>
      <VStack w='full' align='stretch' spacing={16}>
        <Box>
          <Hero />
        </Box>
        <Box>
          <IntegrationLogos />
          <RequestIntegration />
        </Box>
        <Box id="getting-started">
          <GettingStarted />
        </Box>
        <Box id="templates" scrollMarginTop="100px">
          <Templates />
        </Box>
        <Box id="testimonials" scrollMarginTop="100px">
          <Testimonials />
        </Box>
        {/* <Box id="use-cases">
        <UseCases />
      </Box> */}
        {/* <Box id="features">
        <Features />
      </Box> */}
        <Box id="pricing" scrollMarginTop="100px">
          <Pricing />
        </Box>
        <Box id="integrations" scrollMarginTop="100px">
          <IntegrationsWidget />
        </Box>
      </VStack>
      <Box id="faq" scrollMarginTop="100px">
        <Faq />
      </Box>
    </>
  );
};

export default LandingPage;
