'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Text,
    Stack,
    Box,
    SimpleGrid,
    GridItem,
    VStack,
    Heading,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import LandingContainer from 'src/home/LandingContainer'
import Contact from 'src/home/contact'

const FaqEntry = ({ question, answer }) => {
    return (
        <AccordionItem>
            <AccordionButton
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={4}>
                <Text align='start' fontWeight='bold'>{question}</Text>
                <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
                {answer}
            </AccordionPanel>
        </AccordionItem>
    )
}

const Faq = () => {
    return (
        <LandingContainer pt={{ base: 4, lg: 24 }} px={{ base: 2, md: 16 }}
            title='Frequently asked questions'>
            <SimpleGrid spacing={4} columns={{ base: 1, md: 12 }}>
                <GridItem colSpan={{ base: 1, md: 6, lg: 4 }} order={{ base: 1, md: 0 }}>
                    <Contact />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 6, lg: 8 }} order={{ base: 0, md: 1 }}>
                    <Accordion allowMultiple width="full" rounded="lg" defaultIndex={[0]}>
                        <FaqEntry question='I want to integrate with my inbox / Instagram / Hubspot... but I am worried about you do with my data' answer={
                            <VStack align='stretch'>
                                <Text>
                                    Plurally is fully GDPR compliant and we take your privacy very seriously.
                                </Text>
                                <Text>
                                    Your data (email, DMs, hubspot contacts etc...) is used only for executing your flows.

                                    It is never used for any other purpose. That means that your data is not used for training AI models or shared commercially with third parties.
                                </Text>
                                <Text>Your data are accessed on a per-need basis and is not stored on our servers.</Text>
                                <Text>We use SSL technology to encrypt your data and ensure that it is secure.</Text>
                                <Text fontWeight='bold'>
                                    We do not store nor make money from your data.
                                </Text>
                            </VStack>
                        } />
                        <FaqEntry question='For integrating with services, do I need to provide with my password?' answer={
                            <VStack align='stretch'>
                                <Text>
                                    When available we use industry standard OAuth2 authentication to connect with services. This means that you do not need to share your password with us and you can revoke access at any time.
                                </Text>
                                <Text>
                                    In some cases where OAuth2 is not available, we use API keys which are stored securely and encrypted on our servers.They can also be revoked at any time.
                                </Text>
                                <Text>
                                    In some cases where API keys are not available, we will guide you on how to create app-specific passwords which are stored securely and encrypted on our servers.
                                </Text>
                                <Text fontWeight='bold'>
                                    In summary, we never ask for your personal password, for any service.
                                </Text>
                            </VStack >
                        } />
                    </Accordion >
                </GridItem >
            </SimpleGrid >
        </LandingContainer >
    )
}

export default Faq
